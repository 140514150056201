import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import { Layout, ContentWithSideMenu } from 'components'
import {
  Body,
  FullWidthImage,
  Hero,
  MultiColumnBlurb,
  OfficeLocations,
  Team,
  Timeline,
} from 'slices/about'

export const query = graphql`
  query aboutQuery($uid: String!) {
    prismic {
      menu(uid: "about", lang: "en-us") {
        title
        items {
          name
          show_in_side_menu
          link {
            _linkType
            __typename
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
          }
        }
      }
      about(uid: $uid, lang: "en-us") {
        _meta {
          uid
        }
        title
        hero_image
        body {
          ... on PRISMIC_AboutBodyBody {
            type
            primary {
              body_title
              body_content
            }
          }
          ... on PRISMIC_AboutBodyFull_width_image {
            type
            primary {
              image
            }
          }
          ... on PRISMIC_AboutBody3_column_blurb {
            type
            fields {
              column_icon
              column_title
              column_description
            }
          }
        }
        body_below {
          ... on PRISMIC_AboutBody_belowBody {
            type
            primary {
              body_title
              body_content
            }
          }
          ... on PRISMIC_AboutBody_belowFull_width_image {
            type
            primary {
              image
            }
          }
          ... on PRISMIC_AboutBody_belowTimeline {
            type
            primary {
              timeline_title
              timeline_image
            }
            fields {
              year
              year_title
              year_description
            }
          }
          ... on PRISMIC_AboutBody_belowTeam {
            type
            primary {
              team_title
            }
            fields {
              image
              name
              job_title
              company
              biography
            }
          }
          ... on PRISMIC_AboutBody_belowOffice_locations {
            type
            primary {
              locations_title
            }
            fields {
              location_name
              location_address
              geolocation
            }
          }
          ... on PRISMIC_AboutBody_below3_column_blurb {
            type
            fields {
              column_icon
              column_title
              column_description
            }
          }
        }
      }
    }
  }
`

const About = ({
  data: {
    prismic: { about, menu },
  },
}) => {
  return (
    <Layout>
      <Hero data={about} />
      <ContentWithSideMenu menu={menu} uid={get(about, '_meta.uid')}>
        {about.body &&
          about.body.map((slice, idx) => {
            switch (slice.type) {
              case 'body':
                return <Body key={idx} data={slice} />
              case 'full_width_image':
                return <FullWidthImage key={idx} data={slice} />
              case '3_column_blurb':
                return <MultiColumnBlurb key={idx} data={slice} idx={idx} />
              default:
                return null
            }
          })}
      </ContentWithSideMenu>
      {about.body_below && (
        <>
          {about.body_below.map((slice, idx) => {
            switch (slice.type) {
              case 'body':
                return <Body key={idx} data={slice} />
              case 'full_width_image':
                return <FullWidthImage key={idx} data={slice} />
              case '3_column_blurb':
                return <MultiColumnBlurb fullWidth key={idx} data={slice} />
              case 'timeline':
                return <Timeline key={idx} data={slice} />
              case 'team':
                return <Team key={idx} data={slice} />
              case 'office_locations':
                return <OfficeLocations key={idx} data={slice} />
              default:
                return null
            }
          })}
        </>
      )}
    </Layout>
  )
}

About.query = query

export default About
