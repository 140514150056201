import React, { useState } from 'react'
import PrismicDOM from 'prismic-dom'
import styled from 'styled-components'
import { get } from 'lodash'
import system from '@rebass/components'
import { Box, Flex, Image as ImageBase, Text, Link } from 'rebass'
import { colors, fonts } from 'theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.mediumGray};
  flex: 1;
  position: relative;

  @media (min-width: ${p => p.theme.breakpoints[0]}) {
    flex-direction: row;
  }
`

const LeftContainer = system(
  {
    extend: Box,
    flex: [0.75, 0.5],
    pl: ['35px', '45px', '90px'],
    pr: ['35px', '30px'],
    py: ['40px', '80px'],
  },
  'space',
)

const RightContainer = system({
  extend: Flex,
  flex: [0.25, 0.5],
})

const Title = system(
  {
    extend: Text,
    fontSize: '44px',
    fontWeight: '700',
    fontFamily: fonts.main,
    color: colors.mediumBlack,
  },
  'space',
  {
    letterSpacing: '.1em',
    textTransform: 'uppercase',
  },
)

const Image = system(
  {
    extend: ImageBase,
    height: ['30%', 'auto'],
    width: ['100%', 'auto'],
    p: ['20px', '0px'],
  },
  'height',
  'width',
  'space',
  {
    objectFit: 'cover',
  },
)

const Subtitle = system(
  {
    extend: Text,
    mt: '35px',
    maxWidth: '88%',
    fontSize: '24px',
    lineHeight: '1.25',
    color: colors.mediumBlack,
    fontFamily: fonts.main,
  },
  'space',
  'maxWidth',
  {
    letterSpacing: '0.075em',
  },
)

const Description = system(
  {
    extend: Text,
    mt: '30px',
    maxWidth: '96%',
    color: '#777',
    fontFamily: fonts.main,
    fontSize: '15px',
    lineHeight: '1.6',
  },
  'maxWidth',
  'space',
  {
    letterSpacing: '0.075em',
  },
)

const ListHistory = system(
  {
    extend: Box,
    mt: '50px',
    height: '72px',
  },
  'space',
  'height',
)

const ListUl = system(
  {
    extend: Flex,
    as: 'ul',
    display: 'flex',
    position: 'absolute',
    right: ['80px', 'calc(50% + 80px)'],
    left: '0px',
    p: '0px',
    m: '0px',
    pl: '30px',
    maxWidth: 'none',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    listStyle: 'none',
  },
  {
    '&::before': {
      width: '100%',
      background: colors.lightTeal,
      position: 'absolute',
      content: "''",
      top: '17px',
      right: '20px',
      height: '6px',
    },
  },
  'display',
  'space',
  'position',
  'right',
  'left',
  'maxWidth',
  'flexWrap',
  'justifyContent',
  'listStyle',
)

const ListLi = system(
  {
    extend: Box,
    position: 'relative',
    pt: '54px',
  },
  {
    a: {
      color: colors.mediumBlack,
    },
    'a:hover': {
      color: colors.darkTeal,
    },
  },
  'position',
  'space',
)

const BigCircle = system(
  {
    extend: Box,
    width: '40px',
    height: '40px',
    position: 'absolute',
    display: 'inline-block',
    top: '0px',
    left: '5px',
    fontSize: '0px',
    lineHeight: '0',
    bg: colors.white,
    border: `5px solid ${colors.lightTeal}`,
    borderRadius: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  {
    '&::before': {
      width: '11px',
      height: '11px',
      position: 'absolute',
      content: "''",
      top: '50%',
      left: '50%',
      background: colors.lightTeal,
      borderRadius: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
    },
  },
  'width',
  'height',
  'position',
  'display',
  'top',
  'left',
  'fontSize',
  'lineHeight',
  'color',
  'border',
  'borderRadius',
  'transform',
)

const LittleCircle = system(
  {
    extend: Box,
    width: '28px',
    height: '28px',
    position: 'absolute',
    display: 'inline-block',
    top: '6px',
    left: '5px',
    fontSize: '0px',
    lineHeight: '0',
    bg: colors.white,
    border: `5px solid ${colors.lightTeal}`,
    borderRadius: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  'width',
  'height',
  'position',
  'display',
  'top',
  'left',
  'fontSize',
  'lineHeight',
  'color',
  'border',
  'borderRadius',
  'transform',
)

const Year = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: '18px',
    fontWeight: '500',
  },
  {
    letterSpacing: '0.05em',
  },
)

export const Timeline = ({ data }) => {
  const { primary, fields } = data
  const [selected, setSelected] = useState(0)

  return (
    <Container>
      <LeftContainer>
        <>
          {primary && primary.timeline_title && (
            <Title>{PrismicDOM.RichText.asText(primary.timeline_title)}</Title>
          )}
          {fields && fields[selected] && (
            <>
              {fields[selected].year_title && (
                <Subtitle>{PrismicDOM.RichText.asText(fields[selected].year_title)}</Subtitle>
              )}
              {fields[selected].year_description && (
                <Description>
                  {PrismicDOM.RichText.asText(fields[selected].year_description)}
                </Description>
              )}
            </>
          )}
          <ListHistory>
            <ListUl>
              {fields.map((item, idx) => (
                <ListLi>
                  <Link
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setSelected(idx)
                    }}>
                    {idx === selected && <BigCircle />}
                    {idx !== selected && <LittleCircle />}
                    <Year>{item.year}</Year>
                  </Link>
                </ListLi>
              ))}
            </ListUl>
          </ListHistory>
        </>
      </LeftContainer>
      <RightContainer>
        <Image src={get(primary, 'timeline_image.url')} />
      </RightContainer>
    </Container>
  )
}
