import React from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Link } from 'gatsby'
import { Box, Flex, Text, Image as ImageBase } from 'rebass'
import { colors, fonts } from 'theme'
import { RightArrowAlt } from 'styled-icons/boxicons-regular/RightArrowAlt'

const Container = system(
  {
    extend: Box,
    flex: 1,
  },
  'flex',
)

const Top = system(
  {
    extend: Box,
    position: 'relative',
  },
  'position',
)

const Bottom = system(
  {
    extend: Flex,
    bg: '#f3f3f3',
    justifyContent: 'center',
    width: '100%',
    flex: 1,
    py: '20px',
  },
  'color',
  'width',
  'space',
)

const Image = system(
  {
    extend: ImageBase,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    left: '0px',
  },
  'position',
  'width',
  'height',
  'top',
  'left',
  {
    filter: 'brightness(50%)',
    objectFit: 'cover',
  },
)

const TitleContainer = system(
  {
    extend: Flex,
    flexDirection: 'column',
    py: '90px',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
  },
  'space',
  'width',
  'zIndex',
)

const Title = system(
  {
    extend: Text,
    color: colors.white,
    zIndex: 1,
    fontWeight: '700',
    fontFamily: fonts.main,
    fontSize: ['32px', '40px'],
    textAlign: 'center',
  },
  'zIndex',
  {
    letterSpacing: '.1em',
    textTransform: 'uppercase',
  },
)

const Separator = system(
  {
    extend: Box,
    bg: colors.white,
    height: '4px',
    mt: '35px',
    opacity: '75%',
    width: '72px',
    zIndex: 1,
  },
  'color',
  'height',
  'opacity',
  'space',
  'width',
  'zIndex',
)

const Crumb = system(
  {
    extend: Text,
    fontSize: '12px',
    lineHeight: '1.5',
    fontFamily: fonts.main,
    px: ['5px', '17px'],
  },
  'space',
  p => ({
    letterSpacing: '.13em',
    textTransform: 'uppercase',
    color: p.active ? '#151515' : '#777',
    fontWeight: p.active ? '900' : '500',
    whiteSpace: p.noWrap ? 'nowrap' : 'inherit',
    a: {
      color: '#777',
      fontWeight: p.active ? '900' : '500',
    },
    'a:hover': {
      color: colors.darkTeal,
      textDecoration: 'none',
    },
  }),
)

export const Hero = ({ data }) => {
  const { title, hero_image } = data

  return (
    <Container>
      <Top>
        {hero_image && <Image src={hero_image.url} />}
        <TitleContainer>
          <Title>{PrismicDOM.RichText.asText(title)}</Title>
          <Separator />
        </TitleContainer>
      </Top>
      <Bottom>
        <Crumb noWrap>
          <Link to="/">Home</Link>
        </Crumb>
        <RightArrowAlt size={20} />
        <Crumb noWrap>
          <Link to="/about">About</Link>
        </Crumb>
        <RightArrowAlt size={20} />
        <Crumb active>{PrismicDOM.RichText.asText(title)}</Crumb>
      </Bottom>
    </Container>
  )
}
