import React from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Box, Text, Flex, Image as ImageBase } from 'rebass'
import { ContentContainer, Link } from 'components'
import { fonts, colors } from 'theme'
import NoProfile from 'images/no-profile.png'

const Container = system(
  {
    extend: Flex,
    justifyContent: 'center',
    width: '100%',
  },
  'width',
  'space',
)

const FullContainer = system(
  {
    extend: Flex,
    flex: 1,
    flexDirection: ['column', 'column', 'row'],
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: ['100%', '100%', '1100px'],
    width: '100%',
  },
  'width',
  'flex',
  'maxWidth',
)

const Item = system(
  {
    extend: Box,
    bg: colors.white,
    flex: [1, 1, '0 0 45%'],
    maxWidth: ['100%', '100%', '45%'],
    mb: '40px',
    mx: ['0px', '0px', '15px'],
    width: '100%',
    boxShadow: ['none', 'none', `10px 10px 0px ${colors.lightTeal}`],
  },
  'boxShadow',
  'color',
  'maxWidth',
  'space',
  'width',
)

const Image = system(
  {
    extend: ImageBase,
    width: '100%',
    height: '238px',
  },
  'width',
  'height',
  {
    objectFit: 'cover',
  },
)

const TitleContainer = system(
  {
    extend: Flex,
    alignItems: 'center',
    pb: '45px',
  },
  'space',
)

const Separator = system(
  {
    extend: Box,
    height: '1px',
    display: ['none', 'block'],
    bg: colors.lightTeal,
    width: '50px',
    mr: '8px',
    ml: '-58px',
  },
  'display',
  'color',
  'space',
  'width',
  'height',
)

const Title = system(
  {
    extend: Text,
    fontSize: '30px',
    fontWeight: '700',
    fontFamily: fonts.main,
    color: colors.white,
    px: ['none', '15px'],
  },
  'space',
  {
    letterSpacing: '.1em',
    textTransform: 'uppercase',
  },
)

const DescriptionContainer = system(
  {
    extend: Box,
    py: '25px',
    px: '15px',
  },
  'space',
)

const Name = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: '20px',
    lineHeight: '1.25',
    color: '#151515',
    maxWidth: ['100%', '75%'],
  },
  'space',
  'maxWidth',
  {
    letterSpacing: '0.075em',
  },
)

const Description = system(
  {
    extend: Text,
    mt: '8px',
  },
  'space',
  {
    p: {
      fontFamily: fonts.main,
      color: '#777',
      fontSize: '14px',
      lineHeight: '1.6',
      letterSpacing: '0.075em',
      margin: '0px',
      fontWeight: '400',
    },
  },
)

export const OfficeLocations = ({ data }) => {
  const { primary, fields } = data
  const key = process.env.GOOGLE_STATIC_MAP_API_KEY

  const img = ({ latitude, longitude }) => {
    return `https://maps.googleapis.com/maps/api/staticmap?scale=2&center=${latitude},${longitude}&zoom=9&size=477x238&maptype=roadmap
    &markers=color:0x45afab%7C${latitude},${longitude}&key=${key}`
  }

  return (
    <ContentContainer px="15px" pt="45px" bg={colors.darkTeal} justifyContent="flex-start">
      {primary && primary.locations_title && (
        <TitleContainer>
          <Separator />
          <Title>{PrismicDOM.RichText.asText(primary.locations_title)}</Title>
        </TitleContainer>
      )}
      <Container>
        <FullContainer>
          {fields &&
            fields.map((item, idx) => (
              <Item mb={idx === fields.length -1 ? '0px' : '45px'} key={idx}>
                <Link
                  to={`http://www.google.com/maps/place/${PrismicDOM.RichText.asText(
                    item.location_address,
                  )}`}
                  target="_blank">
                  <Image src={item.geolocation ? img(item.geolocation) : NoProfile} />
                  <DescriptionContainer>
                    <Name>{PrismicDOM.RichText.asText(item.location_name)}</Name>
                    <Description
                      dangerouslySetInnerHTML={{
                        __html: PrismicDOM.RichText.asHtml(item.location_address),
                      }}
                    />
                  </DescriptionContainer>
                </Link>
              </Item>
            ))}
        </FullContainer>
      </Container>
    </ContentContainer>
  )
}
