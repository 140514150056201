import React from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Box, Text, Flex, Image as ImageBase } from 'rebass'
import { ContentContainer } from 'components'
import { fonts, colors } from 'theme'
import NoProfile from 'images/no-profile.png'

const Container = system(
  {
    extend: Flex,
    justifyContent: 'center',
  },
  'space',
)

const FullContainer = system(
  {
    extend: Flex,
    flex: 1,
    flexDirection: ['column', 'row'],
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
  },
  'width',
  'flex',
  'maxWidth',
)

const Item = system(
  {
    extend: Box,
    bg: colors.white,
    flex: [1, '0 0 45%'],
    maxWidth: ['100%', '45%'],
    mb: '40px',
    mx: ['0px', '15px'],
    p: '15px',
    width: '100%',
    boxShadow: ['none', `10px 10px 0px ${colors.lightTeal}`],
  },
  'boxShadow',
  'color',
  'maxWidth',
  'space',
  'width',
)

const Image = system(
  {
    extend: ImageBase,
    width: '162px',
  },
  'width',
  {
    objectFit: 'cover',
  },
)

const TitleContainer = system(
  {
    extend: Flex,
    alignItems: 'center',
    pb: '45px',
  },
  'space',
)

const Separator = system(
  {
    extend: Box,
    height: '1px',
    display: ['none', 'block'],
    bg: colors.lightTeal,
    width: '50px',
    mr: '8px',
    ml: '-58px',
  },
  'display',
  'color',
  'space',
  'width',
  'height',
)

const Title = system(
  {
    extend: Text,
    fontSize: '30px',
    fontWeight: '700',
    fontFamily: fonts.main,
    color: colors.white,
    px: ['none', '15px'],
  },
  'space',
  {
    letterSpacing: '.1em',
    textTransform: 'uppercase',
  },
)

const DescriptionContainer = system(
  {
    extend: Flex,
    pt: '25px',
    pr: '15px',
  },
  'space',
)

const JobTitle = system(
  {
    extend: Text,
    fontSize: '14px',
    color: colors.lightTeal,
    fontFamily: fonts.main,
    fontWeight: '500',
    pb: '4px',
  },
  'space',
  {
    letterSpacing: '.1em',
    textTransform: 'uppercase',
  },
)

const Company = system(
  {
    extend: Text,
    fontSize: '12px',
    color: colors.black,
    fontFamily: fonts.main,
    pb: '10px',
  },
  'space',
  {
    letterSpacing: '.075em',
    textTransform: 'uppercase',
  },
)

const Name = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: '24px',
    lineHeight: '1.25',
    color: '#151515',
    ml: '30px',
  },
  'space',
  {
    letterSpacing: '0.075em',
  },
)

const Description = system(
  {
    extend: Text,
  },
  'space',
  {
    p: {
      fontFamily: fonts.main,
      color: '#777',
      fontSize: '15px',
      lineHeight: '1.6',
      letterSpacing: '0.075em',
      margin: '0px',
    },
  },
)

export const Team = ({ data }) => {
  const { primary, fields } = data

  return (
    <ContentContainer px="15px" pt="45px" bg={colors.darkTeal} justifyContent="flex-start">
      {primary && primary.team_title && (
        <TitleContainer>
          <Separator />
          <Title>{PrismicDOM.RichText.asText(primary.team_title)}</Title>
        </TitleContainer>
      )}
      <Container>
        <FullContainer>
          {fields &&
            fields.map((item, idx) => (
              <Item mb={idx === fields.length - 1 ? '0px' : '45px'} key={idx}>
                <JobTitle>{PrismicDOM.RichText.asText(item.job_title)}</JobTitle>
                <Company>{PrismicDOM.RichText.asText(item.company)}</Company>
                <Flex alignItems="center">
                  <Image src={item.image ? item.image.url : NoProfile} />
                  <Name>{PrismicDOM.RichText.asText(item.name)}</Name>
                </Flex>
                <DescriptionContainer>
                  <Description
                    dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(item.biography) }}
                  />
                </DescriptionContainer>
              </Item>
            ))}
        </FullContainer>
      </Container>
    </ContentContainer>
  )
}
